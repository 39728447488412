




































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import InputLabel from "@/components/design-system/inputs/InputLabelWrapper.vue";
@Component({
  components: {
    "input-label": InputLabel
  }
})
export default class NumericInput extends Vue {
  @Prop() inputValue?: number;
  @Prop() isReadOnly!: boolean;
  @Prop({ default: false }) isRequired!: boolean;
  @Prop() labelText!: string;
  @Prop({ default: "" }) customClass!: string;
  @Prop({ default: "" }) placeholder!: string;
  @Prop({ default: false }) appendsDisabled?: boolean;
  @Prop({ default: "default" }) type?: string;
  @Prop({ default: "integer" }) valueType?: string;
  @Prop({ default: function() {} }) blur?: Function;
  @Prop({ default: "" }) prependInnerIcon?: string;
  private value: string = this.inputValue?.toString() || "0";

  //methods
  private increment() {
    let auxValue = parseInt(this.value, 10);
    auxValue++;
    this.value = auxValue.toString();
  }
  private decrement() {
    let auxValue = parseInt(this.value, 10);
    auxValue--;
    this.value = auxValue.toString();
  }

  //watchers
  @Watch("value")
  changed() {
    this.$emit("lookup", this.value ? this.value : 0);
  }
}
