



















































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import NumericInput from "@/components/design-system/inputs/NumericInput.vue";
import SwitchInput from "@/components/design-system/inputs/SwitchInput.vue";
import StandardInput from "@/components/design-system/inputs/StandardInput.vue";
import { serviceManager } from "@/services/settings-services/service-manager";
import { cloneDeep, differenceWith, isEqual } from "lodash";
import { randomNumberGenerator } from "@/helpers/numbers-helpers";

@Component({
  components: {
    "primary-button": PrimaryButton,
    "numeric-input": NumericInput,
    "switch-input": SwitchInput,
    "standard-input": StandardInput
  }
})
export default class CategoryTemplate extends Vue {
  //component props
  @Prop() sectionName!: string;
  @Prop() categoryName!: string;
  @Prop() subcategories!: any;

  //class properties
  private checkboxesPerRow = 3;
  private disabled = true;
  private switchSectionKey: any = null;

  private mutableSubcategories = cloneDeep(this.subcategories);
  private stringifyMutableSubcategories = JSON.stringify(this.subcategories);

  private immutableSubcategories = cloneDeep(this.subcategories);
  private stringifyImmutableSubcategories = JSON.stringify(this.subcategories);

  private changedSettings: any[] = [];

  private asMatrix(checkboxes: any[]) {
    const items = cloneDeep(checkboxes);
    const rows: any[] = [];
    while (items && items.length > 0) {
      rows.push(items.splice(0, this.checkboxesPerRow));
    }
    return rows;
  }

  private async saveChanges() {
    this.mutableSubcategories.forEach((element, index) => {
      const changedValues = differenceWith(
        element.items,
        this.subcategories[index].items,
        isEqual
      );

      if (changedValues.length > 0) {
        this.changedSettings.push(changedValues);
      }
    });
    await serviceManager(this.sectionName).save(this.changedSettings);
    this.$emit("refreshCategoryTemplate");
  }

  private updateActive(value: boolean, index: number, itemIndex: number) {
    this.mutableSubcategories[index].items[itemIndex].active = value;
    this.getSwitchSectionKey = randomNumberGenerator();
    this.buttonActivator();
  }

  private updateChecked(
    value: boolean,
    index: number,
    itemIndex: number,
    checkboxIndex: number
  ) {
    const result = value == null || !value ? false : true;
    const checkboxes = this.mutableSubcategories[index].items[itemIndex][
      "options"
    ];
    if (checkboxes?.length) {
      checkboxes[checkboxIndex].checked = result;
    }
    this.buttonActivator();
  }

  private updateCheckboxTextValue(
    value: boolean,
    index: number,
    itemIndex: number,
    checkboxIndex: number
  ) {
    const checkboxes = this.mutableSubcategories[index].items[itemIndex][
      "options"
    ];
    checkboxes[checkboxIndex]["value"] = value;
  }

  private updateInputValue(value: boolean, index: number, itemIndex: number) {
    this.mutableSubcategories[index].items[itemIndex][
      "value"
    ] = value.toString();
    this.buttonActivator();
  }

  private buttonActivator() {
    this.disabled = this.areClonedSubcategoriesEquals();
  }

  private areClonedSubcategoriesEquals(): boolean {
    return (
      this.stringifyImmutableSubcategories ==
      JSON.stringify(this.mutableSubcategories)
    );
  }

  private resetStringifiedProperties() {
    this.stringifyMutableSubcategories = JSON.stringify(this.subcategories);
    this.stringifyImmutableSubcategories = JSON.stringify(this.subcategories);
  }

  private get getSwitchSectionKey() {
    return this.switchSectionKey;
  }

  private set getSwitchSectionKey(value) {
    this.switchSectionKey = value;
  }

  updateItems() {
    this.mutableSubcategories = cloneDeep(this.subcategories);
    this.immutableSubcategories = cloneDeep(this.subcategories);
    this.resetStringifiedProperties();
    this.disabled = this.areClonedSubcategoriesEquals();
  }

  @Watch("sectionName")
  onSectionNameChange() {
    this.updateItems();
  }

  @Watch("subcategories")
  onSubcategoriesValueChange() {
    this.updateItems();
  }
}
