




import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class SwitchInput extends Vue {
  @Prop() active?;
  @Prop({ default: false }) hidden?: boolean;
  @Prop({ default: true }) dense?: boolean;
  private isActive = this.active || false;

  //watchers
  @Watch("isActive")
  changed() {
    this.$emit("lookup", this.isActive);
  }

  //lyfecicle
  created() {
    this.isActive = this.active;
  }
}
