





























































import { Component, Prop } from "vue-property-decorator";
import CategoryTemplate from "./CategoryTemplate.vue";
import { serviceManager } from "@/services/settings-services/service-manager";
import { randomNumberGenerator } from "@/helpers/numbers-helpers";
import UserRolesMixin from "@/mixins/UserRoles.vue";

interface Options {
  label: string;
  value: string | null;
  checked: boolean;
}

interface Item {
  name: string;
  description: string;
  mandatory: boolean;
  active: boolean;
  type: string;
  value: string;
  options: Options[];
}
interface Subcategory {
  id: string;
  name: string;
  position: number;
  items: Item[];
}
interface Category {
  name: string;
  customerFacing: boolean;
  section: string;
  subcategories: Subcategory[];
}

@Component({
  components: {
    "category-template": CategoryTemplate
  }
})
export default class SettingsTemplate extends UserRolesMixin {
  @Prop() title!: string;
  @Prop() sectionName!: string;

  private settingService = serviceManager(this.sectionName);

  private sectionCategories: Category[] = [];
  private selectedCategoryIndex = 0;
  private itemsListKey = randomNumberGenerator();

  private setSelectedCategoryIndex(index) {
    this.selectedCategoryIndex = index;
    this.updateCategoryToRender();
  }

  //computed
  private updateCategoryToRender() {
    const itemToBeFirst = this.sectionCategories.splice(
      this.selectedCategoryIndex,
      1
    );
    this.sectionCategories.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
    this.sectionCategories = [...itemToBeFirst, ...this.sectionCategories];
    this.itemsListKey = randomNumberGenerator();
    this.selectedCategoryIndex = 0;
  }

  private get selectedCategoryName() {
    return { selectedSidebarItem: this.sectionCategories[0].name };
  }

  private getCategoryObj(resItem: any): Category {
    return {
      name: resItem.categoryName,
      customerFacing: false,
      section: this.sectionName,
      subcategories: [this.getSubcategoryObj(resItem)]
    };
  }

  private getSubcategoryObj(resItem: any): Subcategory {
    return {
      id: resItem.subcategoryID,
      name: resItem.subcategoryName,
      position: resItem.subcategoryOrder,
      items: [this.getItemObj(resItem)]
    };
  }

  private getItemObj(resItem: any): Item {
    return {
      name: resItem.itemName,
      description: resItem.itemDescription,
      mandatory: resItem.itemMandatory,
      active: resItem.itemActive,
      type: resItem.itemType,
      value:
        resItem.itemType == "number" && resItem.itemValue != ""
          ? resItem.itemValue.replace('"', "")
          : resItem.itemValue,
      options: resItem.itemOptions != "" ? JSON.parse(resItem.itemOptions) : []
    };
  }

  //Order response
  private generateSectionsTemplateStructure(res: any[]): Category[] {
    const categories: Category[] = [];
    let loadedCatIndex = 0;
    const watchedCategories = {};

    res.forEach(resItem => {
      if (resItem.categoryName in watchedCategories) {
        //Only Add items to subcategory
        const knownCatIndex = watchedCategories[resItem.categoryName].index;
        if (
          watchedCategories[resItem.categoryName]["subcategories"].some(
            sub => sub.id == resItem.subcategoryOrder
          )
        ) {
          const knownSubcatIndex = watchedCategories[resItem.categoryName][
            "subcategories"
          ].find(subcat => subcat.id === resItem.subcategoryOrder).index;
          categories[knownCatIndex].subcategories[knownSubcatIndex].items.push(
            this.getItemObj(resItem)
          );
        } else {
          const subcatToBeLoaded = this.getSubcategoryObj(resItem);
          const subcatIndex =
            categories[knownCatIndex].subcategories.push(subcatToBeLoaded) - 1;
          watchedCategories[resItem.categoryName].subcategories.push({
            id: resItem.subcategoryOrder,
            index: subcatIndex
          });
        }
      } else {
        loadedCatIndex = categories.push(this.getCategoryObj(resItem)) - 1;
        watchedCategories[resItem.categoryName] = {
          index: loadedCatIndex,
          subcategories: [{ id: resItem.subcategoryOrder, index: 0 }]
        };
      }
    });
    return categories;
  }

  //Get setting section items
  async created() {
    await this.refreshCategoryTemplate();
  }

  async refreshCategoryTemplate() {
    const res = await this.settingService.get();
    this.sectionCategories = this.generateSectionsTemplateStructure(res);
  }
}
